import {createApp} from 'vue'
import {createPinia} from "pinia";
import axios from "axios";

import Bugsnag from "@/plugins/bugsnag";
import vuetify from './plugins/vuetify'
import router from './plugins/router'
import {useUserStore} from './stores/user'
import App from './App.vue'

axios.defaults.baseURL = import.meta.env.VITE_API_URL
// set default headers
axios.defaults.headers = {
  'Content-Type': 'application/json',
  'Accept': 'application/json',
}

// if status code 401 redirect to login
axios.interceptors.request.use(
  config => {
    const token = useUserStore().token
    if (token)
      config.headers.token = token
    return config
  }
)
axios.interceptors.response.use(
  response => response,
  error => {
    if (error.response && error.response.status === 401) {
      useUserStore().resetUser()
      router.push('/login')
    }
    return Promise.reject(error)
  }
)

const pinia = createPinia()
const bugsnagVue = Bugsnag.getPlugin('vue')

createApp(App)
  .use(bugsnagVue)
  .use(pinia)
  .use(router)
  .use(vuetify)
  .mount('#app')
