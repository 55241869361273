<template>
  <v-app>
    <v-app-bar app density="compact" elevation="2">
      <!-- logo -->
      <v-app-bar-nav-icon variant="text" @click.stop="drawer = !drawer"></v-app-bar-nav-icon>


      <v-toolbar-title>
        TicketApp
      </v-toolbar-title>

      <v-spacer></v-spacer>
      <v-img
        src="@/assets/cucos.png"
        style="height: 90%; width: auto; margin-right: 16px;"
        contain
        @click="this.$router.push('/')"
      />
    </v-app-bar>
    <v-navigation-drawer
      v-model="drawer"
      location="left"
      temporary
    >
      <v-list nav>
        <v-list-item
          v-for="item in items"
          @click="item.action"
          density="comfortable"
          color="primary"
          variant="tonal"
          style="padding: 20px"
        >
          <v-list-item-title>{{ item.title }}</v-list-item-title>
          <template v-slot:prepend>
            <v-icon :icon="item.icon"></v-icon>
          </template>
        </v-list-item>
      </v-list>
      <!-- draw app version on bottom -->
      <v-footer app>
        <v-row justify="center" no-gutters>
          <small>Version: 1.2.0</small>
        </v-row>
      </v-footer>
    </v-navigation-drawer>
    <v-main>
      <router-view></router-view>
    </v-main>
  </v-app>
</template>

<script>
import {useUserStore} from '@/stores/user'

export default {
  data: () => ({
    drawer: false,
    group: null,
    items: [],
  }),
  mounted() {
    this.items = [
      {
        title: 'Tickets',
        icon: 'mdi-ticket',
        action: () => this.$router.push('/')
      },
      {
        title: 'Logout',
        icon: 'mdi-logout',
        action: () => {
          useUserStore().resetUser()
          this.$router.push('/login')
        }
      }
    ]
  },
  watch: {},
  methods: {},
}
</script>
