<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <v-card
          theme="cucos"
          :loading="loading"
          class="mx-auto pa-3 pb-2"
          elevation="8"
          max-width="448"
          rounded="lg"
        >
          <v-card-text>
            <v-alert
              :type="message.type"
              dismissible
              elevation="2"
              icon="mdi-alert"
              class="mb-4"
              v-model="message.show"
              :text="message.text"
            ></v-alert>

            <v-form @submit.prevent="">
              <div class="text-subtitle-1 text-medium-emphasis">Account</div>
              <v-text-field
                density="compact"
                placeholder="Email address"
                prepend-inner-icon="mdi-email-outline"
                variant="outlined"
                required
                type="email"
                v-model="email"
              />

              <div class="text-subtitle-1 text-medium-emphasis">Password</div>
              <v-text-field
                :append-inner-icon="visible ? 'mdi-eye-off' : 'mdi-eye'"
                :type="visible ? 'text' : 'password'"
                @click:append-inner="visible = !visible"
                density="compact"
                placeholder="Enter your password"
                prepend-inner-icon="mdi-lock-outline"
                variant="outlined"
                required
                type="password"
                v-model="password"
              />
            </v-form>

            <v-card
              class="mb-6"
              color="surface-variant"
              variant="tonal"
            >
              <v-card-text class="text-medium-emphasis text-caption">
                Warning: After consecutive failed login attempts, you account will be temporarily locked.
              </v-card-text>
            </v-card>
            <v-btn
              class=""
              color="blue"
              size="large"
              variant="tonal"
              block
              :disabled="!email || !password"
              @click="login"
            >
              Log In
            </v-btn>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import axios from "axios";
import {useUserStore} from "@/stores/user";

export default {
  data: () => ({
    userStore: useUserStore(),
    email: null,
    password: null,
    loading: false,
    visible: false,
    message: {
      show: false,
      type: 'warning',
      text: 'Incorrect email or password'
    },
  }),
  mounted() {
    console.log('Auth', this.userStore.isAuthenticated)
  },
  methods: {
    async login() {
      try {
        this.loading = true;
        const response = await axios.post("/v1/authentication", {
          email: this.email,
          password: this.password,
        });
        await this.profile(response.data.data.token);
        this.$router.push('/');
      } catch (error) {
        // 423 Locket, 429 Too many requests
        if (error.response && error.response.status === 400) {
          this.showMessage('Incorrect email or password', 'warning')
          this.password = null
        } else if (error.response && error.response.status === 429) {
          this.showMessage('Too many requests, try again in 10min', 'warning')
        } else if (error.response && error.response.status === 423) {
          this.showMessage('Too many requests, try again in 10min', 'warning')
        } else if (error.response && error.response.status === 403) {
          this.showMessage('Your account deactivated, contact support', 'warning')
        } else {
          this.showMessage('Something went wrong', 'error')
        }
      } finally {
        this.loading = false;
      }
    },
    async profile(token) {
      try {
        const response = await axios.get("/v1/profile", {
          headers: {token: token},
        });
        let res = response.data.data
        this.userStore.setUser(
          res.name,
          token,
          res.permission_id
        )
        console.log('Auth', this.userStore.isAuthenticated)
      } catch (error) {
        console.error(error);
      }
    },
    showMessage(message, type) {
      this.message.show = true;
      this.message.text = message;
      this.message.type = type;
    }
  },
};

</script>
