<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <v-skeleton-loader
          v-if="loading"
          :elevation="2" boilerplate type="card"
        ></v-skeleton-loader>
        <v-alert
          v-if="tickets.length === 0 && !loading"
          type="info"
          dismissible
        >
          No tickets found
        </v-alert>

        <v-text-field
          v-if="tickets.length >= 0 && !loading"
          clearable
          prepend-inner-icon="mdi-magnify"
          variant="underlined"
          label="Search"
          placeholder="Duisburg or 6465"
          v-model="form.search"
        />

        <v-card
          v-for="ticket in filteredTickets"
          :key="ticket.id"

          class="mb-10"
          @click="openTicket(ticket)"
          :color="ticket.id === savedTicketID ? 'green' : 'white'"
          elevation="2"
          link
        >
          <v-card-title class="card-title">
            Ticket № {{ ticket.id }}
          </v-card-title>
          <v-card-text class="card-text">
            <p><b>Planned date:</b> {{ ticket.planned_date }}</p>
            <p><b>Shop number:</b> {{ ticket.store_number }}</p>
            <p>{{ ticket.customer_street }}</p>
            <p>{{ ticket.customer_zipcode }}, {{ ticket.customer_city }}</p>
            <p>Status: {{ ticket.status }}</p>
          </v-card-text>
          <v-card-actions v-if="ticket.id === savedTicketID">
            <v-btn
              block
              variant="outlined"
              color="white"
              @click.stop="openTicket(ticket)"
            >
              Proceed ticket
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
    <v-bottom-sheet v-model="form.warning.show">
      <v-card color="warning">
        <v-card-title>
          <v-icon>mdi-alert</v-icon>
          Warning
        </v-card-title>
        <v-card-text>
          {{ form.warning.message }}
        </v-card-text>
      </v-card>
    </v-bottom-sheet>
  </v-container>
</template>

<script>
import axios from "axios";
import {useUserStore} from "@/stores/user";
import {useTicketStore} from '@/stores/tickets';

export default {
  data: () => ({
    userStore: useUserStore(),
    ticketStore: useTicketStore(),
    tickets: [],
    form: {
      search: null,
      warning: {
        show: false,
        timeout: null,
        message: ''
      },
    },
    savedTicketID: false,
    currentPage: 1,
    perPage: 100,
    loading: true,
    totalPages: 0,
  }),
  mounted() {
    this.checkState()
    this.getTickets()
    window.addEventListener('scroll', this.handleScroll);
  },
  beforeUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  },
  computed: {
    filteredTickets() {
      if (!this.form.search) return this.tickets;

      let search = this.form.search.toLowerCase();
      let fields = ['store_number', 'customer_city', 'customer_street', 'customer_zipcode'];
      return this.tickets.filter(ticket => {
        return fields.some(field => {
          return ticket[field] && ticket[field].toString().toLowerCase().includes(search);
        });
      });
    },
  },
  methods: {
    async getTickets() {
      this.loading = true;
      try {
        const response = await axios.get(`/v2/tickets?page=${this.currentPage}&per_page=${this.perPage}`);
        if (response.data.data.current_page > response.data.data.last_page) {
          return;
        }
        this.tickets = this.tickets.concat(response.data.data.data);
        this.ticketStore.setTickets(this.tickets);
        this.currentPage++;
      } catch (error) {
        console.error(error);
      } finally {
        this.loading = false;
      }
    },
    checkState() {
      let data = localStorage.getItem('close_ticket')
      if (data) {
        try {
          data = JSON.parse(data);
          this.savedTicketID = parseInt(data.ticket_id);
        } catch (e) {
          console.error(e);
        }
      }
    },
    handleScroll() {
      const scrollPosition = window.scrollY + window.innerHeight;
      const pageHeight = document.documentElement.scrollHeight;
      if (scrollPosition >= pageHeight && !this.loading) {
        this.getTickets();
      }
    },
    openTicket(ticket) {
      if (!ticket.project_id)
        return this.showWarning('Contact support, problem: ticket not in project', 10);
      this.$router.push({
        name: 'Ticket',
        params: {
          id: ticket.id
        }
      });
    },
    showWarning(message, timeout) {
      if (this.form.warning.show) clearTimeout(this.form.warning.timeout);
      this.form.warning.show = true;
      this.form.warning.message = message;
      this.form.warning.timeout = setTimeout(() => {
        this.form.warning.show = false;
      }, timeout * 1000);
    },
  },
};

</script>

<style scoped>
.card-title {

}

.card-text p {
  padding-top: 0.5rem;
  font-size: 1rem;
}
</style>
