<template>
  <v-container>
    <v-skeleton-loader
      v-if="fields.length === 0"
      :elevation="2"
      boilerplate
      type="article"
    ></v-skeleton-loader>
    <v-row v-if="fields.length > 0">
      <v-col>
        <v-btn
          block
          size="x-large"
          color="#5cb85c"
          rounded="xs"
          variant="flat"
          @click="previousField"
        >
          <v-icon size="x-large">mdi-arrow-left</v-icon>
        </v-btn>
      </v-col>
      <v-col v-if="!isCloseConfirmation">
        <v-btn
          block
          color="#5cb85c"
          size="x-large"
          rounded="xs"
          :variant="isFormValid ? 'flat' : 'tonal'"
          @click="nextField"
        >
          <v-icon size="x-large">mdi-arrow-right</v-icon>
        </v-btn>
      </v-col>
    </v-row>

    <v-row v-if="isCloseConfirmation">
      <v-col cols="12" style="position:fixed; bottom: 0">
        <v-btn
          class="mb-2"
          block
          color="info"
          size="x-large"
          rounded="xs"
          variant="outlined"
          @click="addField('comment', 'Comment')"
        >
          Add comment
        </v-btn>
        <v-btn
          class="mb-2"
          block
          color="info"
          size="x-large"
          rounded="xs"
          variant="outlined"
          @click="addField('file', 'custom_media')"
        >
          Add Photo
        </v-btn>
        <v-btn
          block
          color="#5cb85c"
          size="x-large"
          rounded="xs"
          variant="flat"
          @click="closeTicket"
        >
          Close Ticket
        </v-btn>
      </v-col>
    </v-row>

    <v-row v-if="fieldType === 'text'">
      <v-col>
        <v-textarea
          v-model="ticket[field.name]"
          :label="field.label"
          :required="field.required"
        >
        </v-textarea>
      </v-col>
    </v-row>

    <v-row v-if="fieldType === 'comment'">
      <v-col>
        <v-textarea
          v-model="ticket[field.name]"
          :label="field.label"
          :required="field.required"
        >
        </v-textarea>
      </v-col>
    </v-row>

    <v-row v-else-if="fieldType === 'select'">
      <v-col>
        <v-select
          v-model="ticket[field.name]"
          :label="field.label"
          :items="fieldSelectOptions"
        >
        </v-select>
      </v-col>
    </v-row>

    <v-row v-else-if="fieldType === 'file'">
      <v-col cols="12">
        <h3 style="width: 100%; text-align: center">{{ field.label }}</h3>
        <v-img
          v-if="ticket[field.name]"
          :src="ticket[field.name]"
          width="auto"
          min-height="100px"
          height="80%"
        >
          <template v-slot:placeholder>
            <div class="d-flex align-center justify-center fill-height">
              <v-progress-circular
                color="green"
                indeterminate
              ></v-progress-circular>
            </div>
          </template>
          <template v-slot:error>
            <v-icon color="red">mdi-alert-circle</v-icon>
          </template>
        </v-img>
      </v-col>
      <v-col cols="12" style="position:fixed; bottom: 0;">
        <input
          style="display: none"
          ref="uploader"
          type="file"
          @change="chooseFromLibrary"
          accept="capture=camera,image/*,video/*"
        >
        <v-btn
          bottom
          block
          :variant="form.fileUploading ? 'plain' : 'elevated'"
          color="#5cb85c"
          size="x-large"
          rounded="xs"
          elevation="2"
          prepend-icon="mdi-camera"
          :loading="form.fileUploading"
          @click="handleFileUploadButton"
        >
          Add Photo
          <template v-slot:loader>
            <v-progress-linear
              stream
              height="10"
              color="blue"
              rounded
              v-model="form.fileUploadProgress"
            >
            </v-progress-linear>
          </template>
        </v-btn>
      </v-col>
    </v-row>

    <v-bottom-sheet
      v-model="form.status.show"
      inset
      :close-on-back="false"
      :close-on-content-click="false"
      :close-delay="3000"
      persistent
    >
      <v-card color="success">
        <v-card-title>
          <v-icon>mdi-check</v-icon>
          Success
        </v-card-title>
        <v-card-text>
          {{ form.status.message }}
        </v-card-text>
      </v-card>
    </v-bottom-sheet>

    <v-bottom-sheet v-model="form.warning.show">
      <v-card color="warning">
        <v-card-title>
          <v-icon>mdi-alert</v-icon>
          Warning
        </v-card-title>
        <v-card-text>
          {{ form.warning.message }}
        </v-card-text>
        <template v-slot:actions v-if="fieldType === 'file'">
          <v-btn
            v-if="fieldType === 'file'"
            append-icon="mdi-chevron-right"
            color="primary"
            text="Unable to make a photo"
            variant="flat"
            block
            @click="field.type = 'comment'; form.warning.show = false;"
          ></v-btn>
        </template>
      </v-card>
    </v-bottom-sheet>
    <v-dialog
      v-model="form.fileUploading"
      max-width="320"
      persistent
    >
      <v-list
        class="py-2"
        color="primary"
        elevation="12"
        rounded="lg"
      >
        <v-list-item
          prepend-icon="mdi-upload"
          :title="'Uploading ' + form.fileUploadProgress + '%'"
        >
          <template v-slot:prepend>
            <div class="pe-4">
              <v-icon color="primary" size="x-large"></v-icon>
            </div>
          </template>

          <template v-slot:append>
            <v-progress-circular
              color="green"
              indeterminate="disable-shrink"
              size="24"
              width="4"
            ></v-progress-circular>
          </template>
        </v-list-item>
      </v-list>
    </v-dialog>
  </v-container>

</template>

<script>
import axios from "axios";
import {useUserStore} from "@/stores/user";

export default {
  props: {
    ticket_id: {
      type: String,
      required: true
    },
    company_id: {
      type: String,
      required: true
    },
    project_id: {
      type: String,
      required: true
    }
  },
  data: () => ({
    userStore: useUserStore(),

    fields: [],
    ticket: {},
    comments: [],
    comment: '',
    form: {
      fieldIndex: 0,

      showPhotoOptions: false,
      unableToTakePhoto: false,

      fileUploadProgress: 0,
      fileUploading: false,

      warning: {
        show: false,
        timeout: null,
        message: ''
      },
      status: {
        show: false,
        message: 'Ticket successfully closed'
      }
    },
  }),
  created() {
    if (!this.loadState())
      this.getFields();
  },
  mounted() {

  },
  computed: {
    field() {
      if (this.form.fieldIndex < this.fields.length) {
        return this.fields[this.form.fieldIndex].field;
      }
    },
    fieldType() {
      if (this.form.fieldIndex < this.fields.length) {
        return this.fields[this.form.fieldIndex].field.type;
      }
    },
    fieldSelectOptions() {
      if (this.fieldType === 'select') {
        let values = this.field.values.split(',')
        let labels = this.field.values_labels.split(',')
        let options = []
        for (let i = 0; i < values.length; i++) {
          options.push({
            value: values[i],
            title: labels[i]
          })
        }
        return options
      }
    },
    isFirstField() {
      return this.form.fieldIndex === 0;
    },
    isLastField() {
      return this.form.fieldIndex === this.fields.length - 1;
    },
    isCloseConfirmation() {
      return this.form.fieldIndex === this.fields.length && this.fields.length > 0;
    },
    isFormValid() {
      if (this.fields.length > 0
        && this.form.fieldIndex < this.fields.length
        && this.fields[this.form.fieldIndex].required === 1) {
        if (this.fieldType === 'text' || this.fieldType === 'file' || this.fieldType === 'select')
          return typeof this.ticket[this.field.name] !== 'undefined' && this.ticket[this.field.name] !== '';
      }
      return true;
    }
  },
  watch: {
    'form.fieldIndex': function (newIndex) {
      this.form.warning.show = false;
      this.form.showPhotoOptions = false;
      this.form.unableToTakePhoto = false;
      this.form.fileUploadProgress = 0;
      this.saveState()
    },
  },
  methods: {
    showWarning(message, timeout) {
      if (this.form.warning.show) clearTimeout(this.form.warning.timeout);
      this.form.warning.show = true;
      this.form.warning.message = message;
      this.form.warning.timeout = setTimeout(() => {
        this.form.warning.show = false;
      }, timeout * 1000);
    },
    clearState() {
      localStorage.removeItem('close_ticket');
    },
    loadState() {
      let data = localStorage.getItem('close_ticket');
      if (data) {
        try {
          data = JSON.parse(data);
          if (data.ticket_id === this.ticket_id
            && data.company_id === this.company_id
            && data.project_id === this.project_id) {
            this.ticket = data.data.ticket;
            this.fields = data.data.fields;
            this.comments = data.data.comments;
            this.form.fieldIndex = data.fieldIndex;
            return true;
          }
        } catch (error) {
          console.error('Error loading state:', error);
          this.clearState()
          return false;
        }

      }
      return false;
    },
    saveState() {
      let data = {
        fieldIndex: this.form.fieldIndex,
        ticket_id: this.ticket_id,
        company_id: this.company_id,
        project_id: this.project_id,
        time: new Date().getTime() / 1000,
        data: {
          ticket: this.ticket,
          fields: this.fields,
          comments: this.comments
        },
      }
      localStorage.setItem('close_ticket', JSON.stringify(data));
    },
    async getFields() {
      let res = await axios.get(`/v2/company-fields/${this.company_id}/project/${this.project_id}`)
      this.fields = res.data.data;
    },
    previousField() {
      if (this.isFirstField)
        return this.$router.push({
          name: 'Ticket',
          params: {
            id: this.ticket_id
          }
        });
      this.form.fieldIndex--;
    },
    nextField() {
      if (!this.isFormValid) {
        return this.showWarning('Current field are required', 5);
      }
      if (!this.isCloseConfirmation) {
        if (this.fieldType === 'comment')
          this.collectMissingImageReason();
        this.form.fieldIndex++;
      }
    },
    addField(type, name) {
      this.fields.push({
        field: {
          name: name,
          label: name,
          type: type,
          required: 0
        }
      });
    },
    collectMissingImageReason() {
      if (!this.field.collected) {
        this.comments.push({
          comment: this.ticket[this.field.name],
          missing_images: this.field.name
        });
        this.field.collected = true;
      }
    },
    handleFileUploadButton() {
      this.$refs.uploader.click()
    },
    async chooseFromLibrary(event) {
      try {
        const file = event.target.files[0];
        if (!file) return this.showWarning('No file selected', 5);

        this.form.fileUploading = true;
        const formData = new FormData();
        formData.append('file', file);
        formData.append('ticket_id', this.ticket_id);
        formData.append('media_name', this.field.name);

        const response = await axios.post('/v1/media', formData, {
          headers: {'Content-Type': 'multipart/form-data'},
          onUploadProgress: (progressEvent) => {
            this.form.fileUploadProgress = Math.round((progressEvent.loaded * 100) / progressEvent.total);
          }
        });
        let image_url = response.data.data.media.url;
        let domain = axios.defaults.baseURL;
        this.ticket[this.field.name] = `${domain}/v1/${image_url}?token=${this.userStore.getToken}`;
        console.log('Image uploaded:', this.ticket[this.field.name])
        this.closePhotoOptions();
      } catch (error) {
        this.showWarning('Error uploading file: ' + error, 30);
      } finally {
        this.form.fileUploading = false;
      }
    },
    closePhotoOptions() {
      this.form.showPhotoOptions = false;
    },
    async closeTicket() {
      try {
        let data = Object.assign({}, this.ticket);
        data.comments = this.comments;
        const response = await axios.post(`/v1/ticket/${this.ticket_id}/close`, data);
        this.form.status.show = true;
        setTimeout(() => {
          this.form.status.show = false;
          this.$router.push('/');
        }, 3 * 1000);
      } catch (error) {
        if (error.response && error.response.status === 422) {
          this.showWarning('Problem: please contact support, reason: ticket are not ready', 30);
        } else {
          this.showWarning('Unable to close ticket: ' + error, 30);
        }
      } finally {
        this.clearState()
      }
    }
  }
};
</script>

<style scoped>
.control-button-group {
  display: flex;
  justify-content: space-around;
  gap: 1rem;
  margin-top: 1rem;
}

.btn {
  padding-block: 0.75rem;
  font-size: 1.2rem;
  font-weight: 500;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  touch-action: manipulation;
  user-select: none;
  background-image: none;
  border: 1px solid transparent;
  border-radius: 4px;
  color: #fff;
  background-color: #5cb85c;
  border-color: #4cae4c;
}

.disabled {
  background-color: lightgray;
  border-color: lightgray;
}

.control-button-group > .btn {
  width: 25vw;
}

.fields {
  margin-bottom: 10px;
}

.field-group {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  margin-top: 1rem;
}

.field-text {
  font-size: 1.2rem;
  margin-inline: 1rem;
}

.field-input {
  border: 1px solid #ccc;
  font-size: 1.2rem;
  width: calc(100vw - 2rem);
  height: 2.5rem;
  padding: 0.5rem;
}

.required-field {
  color: red;
}

.fileUploaded {
  color: green;
}

.bottom-button-group {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  margin-bottom: 2rem;
}

.bottom-button-group .btn {
  width: 50vw;
}

.photo-options-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.25);
  z-index: 99;
  display: flex;
  justify-content: center;
  align-items: center;
}

.fileUploadProgress {
  font-size: 1.2rem;
  padding: 2rem;
  border-radius: 8px;
  background-color: white;
}

.photo-options-modal {
  position: fixed;
  bottom: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  padding: 2rem;
  margin: 1rem;
  border-radius: 8px;
  background-color: #fff;
}

.photo-options-modal > .btn {
  width: 75vw;
}
</style>
